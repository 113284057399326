/**
 * project: user
 * fileName: downloadList
 * Created by liujianjiu on 2020/07/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import BaseService from "../BaseService";

class DownLoadlList extends BaseService {
  /**
   * 查询下载列表数据
   * @param params 查询参数
   * @returns {*}
   */
  queryDataList(params) {
    return this.http.post("queryDownCenterList", params);
  }
}

const downLoadlList = new DownLoadlList();
export default downLoadlList;
